export const environment = {
    production: true,
    serviceWorker: true,
    build: "test",
    version: "2.3.8",

    // // Gibt an, wie alt ein Forecast sein darf, bevor dieser neu geladen wird.
    // // Diese Einstellung sollte im Frontend ca. 10 min. größer sein als im Backend.
    // forecast_max_age_minutes: 30,
    //
    // // Gibt an, in welchem Intervall geprüft wird, ob alle Forecasts noch aktuell sind.
    // forecast_update_interval_minutes: 1,
    //
    // // Gibt an, in welchem Intervall geprüft wird, ob sich der Ort geändert hat.
    // location_update_interval_minutes: 1,

    // apiBaseUrl: "http://192.168.18.10:8082/v01/readonly"
    apiBaseUrlReadOnly: "https://test-api.mobilewetter.com/v01/readonly",
    // apiBaseUrl: "https://api.mobilewetter.com/v01/readonly"
    // apiBaseUrlLog: "http://192.168.18.10:8082/v01/logging",

    glitchTip: {
        dsn: "https://2f20be84559e41a3b1910afe39a68a8e@glitchtip.mobilewetter.com/2",
        tracesSampleRate: 1.0,
    },

    // Taboola-Einstellungen
    taboolaActivated: false,

    // Backend
    backendBaseUrl: "https://test.backend.mobilewetter.com",

    // Interval des globalen Intervall-Timers in Minuten.
    // Gibt an, nach wieviel Minuten automatische Vorgänge wie z.B. das Erneuern von Forecasts
    // durchgeführt werden sollen.
    globalTimerMinutes: 1,

    // Beim Speichern des Forecasts in die DB wird dieser TTL übergeben.
    // Nach Ablauf dieses TTLs wird der Forecast aus der DB gelöscht.
    forecastTtlMinutes: 10,

    // Beim Speichern von Koordinaten in die DB wird dieser TTL übergeben.
    // Nach Ablauf dieses TTLs werden die Koordinaten aus der DB gelöscht.
    coordinatesTtlDays: 60,

    // Beim Speichern von Teasern in die DB wird dieser TTL übergeben.
    // Nach Ablauf dieses TTLs werden die Teaser aus der DB gelöscht.
    teasersTtlDays: 60,
}
